// stores inspired by svelte. these allow you to register callbacks to data
// changes in order to write reactive pages.

type Subscriber<T> = (value: T) => void;
type Updater<T> = (value: T) => T;
type Unsubscriber = () => void;
export class Store<T> {
  constructor(initial_value: T) {
    this.value = initial_value;
  }

  protected value: T;
  protected subscribers: Subscriber<T>[] = [];

  public subscribe(subscriber: Subscriber<T>): Unsubscriber {
    this.subscribers.push(subscriber);

    return () => {
      this.remove_subscriber(subscriber);
    };
  }

  public clear_subscribers() {
    this.subscribers = [];
  }

  public get(): T {
    return this.value;
  }

  public set(new_value: T) {
    this.value = new_value;
    this.subscribers.forEach(callback => callback(this.value));
  }

  public update(updater: Updater<T>) {
    this.set(updater(this.value));
  }

  protected remove_subscriber(subscriber: Subscriber<T>) {
    const index = this.subscribers.indexOf(subscriber);
    if (index !== -1) this.subscribers.splice(index, 1);
  }
}
