type BabbleId = string;

// I sorted these letters by the "Relative frequency in the English language"
// table here: https://en.wikipedia.org/wiki/Letter_frequency
// Retrieved 2022-03-21 @ 11:18
//
// This makes reasonably english-feeling alphanumerical identifiers.
const VOWELS = [
  `e`, `e`, `e`, `e`, `e`, `e`,
  `a`, `a`, `a`, `a`,
  `i`, `i`, `i`, `i`,
  `o`, `o`, `o`,
  `u`,
  `y`,
];

const CONSONANTS = [
  `s`, `s`, `s`, `s`, `s`, `s`, `s`, `s`,
  `r`, `r`, `r`, `r`, `r`, `r`, `r`,
  `n`, `n`, `n`, `n`, `n`, `n`, `n`,
  `m`, `m`, `m`, `m`, `m`, `m`,
  `t`, `t`, `t`, `t`, `t`, `t`,
  `d`, `d`, `d`, `d`, `d`,
  `l`, `l`, `l`, `l`, `l`,
  `c`, `c`, `c`, `c`,
  `g`, `g`, `g`,
  `p`, `p`, `p`,
  `k`, `k`, `k`,
  `h`, `h`,
  `b`, `b`,
  `f`, `f`,
  `v`,
  `w`,
  `j`,
  `z`,
  `x`,
  `q`,
];

// I tried generating 10000 of these in chromium to see the performance
// characteristics. While running profiling, it took 225ms to generate them.
// That's 0.0225 ms per id. It will take longer if you're generating one at a
// time, but I think we're safe as far as performance is concerned.
export default function generate(length = 20): BabbleId {
  if (length < 1) {
    throw new Error(`length must be greater than 0`);
  }
  if (length > 3000) {
    // eslint-disable-next-line no-console
    console.warn(`surely`, length, `is an excessive length for a BabbleID?`);
  }

  const characters = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    characters.push(sampleArray(VOWELS));
    if (characters.length >= length) break;

    characters.push(sampleArray(CONSONANTS));
    if (characters.length >= length) break;
  }

  return characters.join(``);
}

function sampleArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}
