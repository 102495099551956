import {ohNo} from '@/modules/oh_no';

// https://developer.mozilla.org/en-US/docs/Web/API/Web_components/Using_custom_elements
// https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define

export function defineCustomElement(name, constructor, options) {
  if (`customElements` in window) {
    // defining the same custom element multiple times will throw an error
    // I could not find this error in the documentation, but it happened in
    // chromium 110.0.5481.100
    if (window.customElements.get(name)) {
      ohNo(`custom element defined more than once: ${name}`);
    } else {
      window.customElements.define(name, constructor, options);
    }
  } else if (`registerElement` in document) {
    ohNo(`users browser has registerElement customElementsapi v0`);
  } else {
    ohNo(`users browser does not support any kind of customElements`);
  }
}
