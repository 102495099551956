/*
module for client-wide error handling and reporting helpers.
*/

import I18n from '@/modules/i18n';

interface OhNoOptions {
  report?: boolean;
  // request errors are being logged twice, which is annoying.
  // this option helps reduce that spam.
  logReporting?: boolean;
  groupingHash?: string;
}

export function ohNo(error: unknown, options?: OhNoOptions): void {
  invisibleOhNo(error, options);

  alertifySomethingWentWrongReported();
}

// This function is invisible to the end user. No alertify toast.
export function invisibleOhNo(error: unknown, options?: OhNoOptions) {
  // Make sure that the developer can open the console, like the message popup
  // instructs, and see a stacktrace if nothing else. Without this it can be
  // really difficult and annoying to track down where an error comes from.
  // eslint-disable-next-line no-console
  console.error(`Dear developer:`, stringifyError(error));

  if (process.env.RAILS_ENV === `production`) {
    reportError(error, options);
  } else {
    showDeveloperErrorDialog(stringifyError(error, {html: true}));
  }
}

export function alertifySomethingWentWrongReported() {
  alertify.error(I18n.t(`something_went_wrong_we_will_investigate`), 20);
}

export function alertifySomethingWentWrong() {
  alertify.error(I18n.t(`something_went_wrong`), 20);
}

export function reportError(error: unknown, options?: OhNoOptions) {
  const msg = stringifyError(error);
  if (options?.logReporting === undefined || options?.logReporting === true) {
    // eslint-disable-next-line no-console
    console.warn(`reportError(): ` + msg);
  }

  // Not sure how much this actually helps but ideally bugsnag should now get
  // a stacktrace to make it easier to track down errors. Like assumptions did.
  const errorForStacktrace = new Error(msg);

  if (options?.report !== false) {
    if (window.Bugsnag) {
      window.Bugsnag.notify(errorForStacktrace, (bsEvent: any) => {
        if (options?.groupingHash) {
          bsEvent.groupingHash = options.groupingHash;
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Bugsnag is not defined, discarding error.`);
    }
  }
}

interface StringifyOptions {
  html?: boolean;
}

export function stringifyError(error: unknown, options?: StringifyOptions): string {
  const html = options?.html;

  let message = ``;
  if (error === undefined || error === null) {
    message += `<error was undefined or null :(>`;
  } else if (typeof error === `string`) {
    message += `Error (string): `;
    if (html) message += `<br>`;
    message += error;
  } else if (isError(error)) {
    message += `Error (Error instance): `;
    if (html) message += `<br>`;
    message += stringifyErrorInstance(error);
  } else {
    message += `Error (dictionary object): `;
    if (html) message += `<br>`;
    message += stringifyDictionary(error as object);
  }
  return message;
}

function isError(object: unknown) {
  return object instanceof Error;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
function stringifyErrorInstance(object: any) {
  const string = `${object.name}: ${object.message}`;

  return string;
}

function stringifyDictionary(dictionary: object) {
  let msg = ``;
  Object.entries(dictionary).forEach(function(message) {
    msg += `  ${message[0]}: ${message[1]} <br>`;
  });
  return msg;
}

const DEVONLY_MESSAGE = `DEVONLY: Something bad happened. Likely a developer
                         mistake. Come find me! (console should contain more
                         details)`;

function showDeveloperErrorDialog(content: string) {
  const holder = document.createElement(`div`);
  // I had to debug a test where a dialog was causing test
  // failures, but all capybara could tell me was "click intercepted by
  // <div class="popup-dialog">". For this reason, it is important that the
  // name of this class be recognizable enough to find this code quickly.
  holder.classList.add(`developer-error-dialog`);
  holder.innerHTML = `
    <h4>${DEVONLY_MESSAGE}</h4>
    <br>
    <h3>${content}</h3>
    <i id="close-dev-help" class="fa fa-times"></i>
  `;
  document.body.appendChild(holder);

  holder
    .querySelector(`#close-dev-help`)!
    .addEventListener(`click`, () => closeDialog(holder));
}

function closeDialog(element: Element) {
  if (element.parentNode !== null) {
    element.parentNode.removeChild(element);
  }
}
